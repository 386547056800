<template>
  <b-container fluid>
    <ValidationObserver ref="customerFormObserver" v-slot="{ invalid }">
      <form>
        <!--User Information -->
        <b-row>
          <b-col lg="12">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ isEdit ? $t("EditCustomer") : $t("NewCustomer") }}</h4>
              </template>
              <template v-slot:body>
                <div class="new-user-info">
                  <b-row>
                    <b-form-group class="col-md-4"
                                  :label="$t('NameSurname')"
                                  label-for="fname">
                      <ValidationProvider name="First and last name" rules="required" v-slot="{ errors }">
                        <b-form-input v-model="model.name"
                                      type="text"
                                      maxlength="400"
                                      :class="(errors.length > 0 ? ' is-invalid' : '')" required></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ $t('ValidationMessage.CannotBeEmpty') }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group class="col-md-4"
                                  :label="$t('IdentityNumber')"
                                  label-for="idnumber">
                      <ValidationProvider name="Identity number"
                                          :rules="identityNumberRule"
                                          v-slot="{ errors }">
                        <b-form-input v-model="model.nationalNumber"
                                      maxlength="20"
                                      type="text"
                                      :class="(errors.length > 0 && setRequired('citizenshipID_Required') ? ' is-invalid' : '')"></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ $t('ValidationMessage.CannotBeEmpty') }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group class="col-md-4"
                                  label-for="gender"
                                  :label="$t('Gender')">
                      <v-select transition="" v-model="model.genderID"
                                :reduce="s => s.id" label="name"
                                :options="genderList"
                                name="GenderID">
                        <template v-slot:no-options>
                          {{$t('NoMatchingOptionsMessage')}}
                        </template>
                      </v-select>
                    </b-form-group>
                    <b-form-group class="col-md-4"
                                  label-for="nationality"
                                  :label="$t('Nationality')">
                      <v-select transition="" v-model="model.nationalityCountryID"
                                :reduce="s => s.id" label="name"
                                :options="countryList"
                                name="Nationality">
                        <template v-slot:no-options>
                          {{$t('NoMatchingOptionsMessage')}}
                        </template>
                      </v-select>
                    </b-form-group>
                    <b-form-group class="col-md-4"
                                  :label="$t('Phone')"
                                  label-for="phone">
                      <phone-number v-model.lazy="model.phoneObject"
                                    :objectId="model.id"
                                    :allow-dublicate-number="setRequired('blockOnDuplicatePhoneNumber')" />
                    </b-form-group>
                    <b-form-group class="col-md-4"
                                  :label="$t('Email')"
                                  label-for="email">
                      <ValidationProvider vid="email" name="E-mail" rules="email" v-slot="{ errors }">
                        <input type="email"
                               :class="'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')"
                               id="emailInput"
                               v-model="model.eMail"
                               :required="setRequired('emailRequired')">
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group class="col-md-4"
                                  label-for="referenceSource"
                                  :label="$t('ReferenceSource')">
                      <ValidationProvider name="ReferenceSource" :rules="referenceSourceRules" v-slot="{ errors }">
                        <v-select transition="" v-model="model.referenceSourceID"
                                  :reduce="s => s.id" label="name"
                                  :options="referenceSourceList"
                                  name="ReferenceSource"
                                  :class="(errors.length > 0 && setRequired('referenceSourceRequired') ? ' is-invalid' : '')">
                          <template v-slot:no-options>
                            {{$t('NoMatchingOptionsMessage')}}
                          </template>
                        </v-select>
                        <div class="invalid-feedback">
                          <span>{{ validationVSelectErrors.ReferenceSourceID }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group class="col-md-4"
                                  :label="$t('HomePhone')"
                                  label-for="phoneHome">
                      <b-form-input v-model="model.phoneHome"
                                    maxlength="50"></b-form-input>
                    </b-form-group>
                    <b-form-group class="col-md-4"
                                  :label="$t('WorkPhone')"
                                  label-for="phoneWork">
                      <b-form-input v-model="model.phoneWork"
                                    maxlength="50"></b-form-input>
                    </b-form-group>
                    <b-form-group class="col-md-4"
                                  :label="$t('DateOfBirth')"
                                  label-for="dateOfBirth">
                      <date-picker v-model="model.dateOfBirth"
                                   :tag-name="'DateOfBirth'"
                                   :is-required="setRequired('dateOfBirthRequired') ? true : false"
                                   :max-date-today="true" />
                      <div class="invalid-feedback">
                        {{ validationVSelectErrors.DateOfBirth }}
                      </div>
                    </b-form-group>
                    <b-form-group class="col-md-4"
                                  label-for="maritalStatus"
                                  :label="$t('MaritalStatus')">
                      <v-select transition="" v-model="model.marialStatusID"
                                :reduce="s => s.id" label="name"
                                :options="marialStatusList"
                                name="MarialStatusID">
                        <template v-slot:no-options>
                          {{$t('NoMatchingOptionsMessage')}}
                        </template>
                      </v-select>
                    </b-form-group>
                    <b-form-group class="col-md-4"
                                  :label="$t('Job')"
                                  label-for="job">
                      <job-tags-input :job="model.customer_OtherInformation.job"></job-tags-input>
                    </b-form-group>
                  </b-row>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
        <b-row v-if="model.nationalityCountryID!=null && model.nationalityCountryID!=212"> 
          <b-col lg="12">
            <iq-card>
              <template v-slot:body>
                <div class="new-user-info">
                  <h5 class="mb-3">{{ $t('ENabiz') }}</h5>
                  <b-row>
                    <b-form-group class="col-md-4"
                                  label-for="foreignPatientType"
                                  :label="$t('ForeignPatientType')">
                      <v-select transition="" v-model="model.foreignPatientTypeID"
                                :reduce="s => s.id" label="name"
                                :options="foreignPatientTypeList"
                                name="ForeignPatientType">
                        <template v-slot:no-options>
                          {{$t('NoMatchingOptionsMessage')}}
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-row>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12">
            <iq-card>
              <template v-slot:body>
                <div class="new-user-info">
                  <h5 class="mb-3">{{ $t('AddressInformations') }}</h5>
                  <b-row>
                    <b-form-group class="col-md-4"
                                  label-for="country"
                                  :label="$t('Country')">
                      <ValidationProvider name="Country" :rules="countryRules" v-slot="{ errors }">
                        <v-select transition="" v-model="model.countryID"
                                  :reduce="s => s.id" label="name"
                                  :searchable="true"
                                  :options="countryList"
                                  name="Country"
                                  :class="(errors.length > 0 && setRequired('countryRequired') ? ' is-invalid' : '')">
                          <template v-slot:no-options>
                            {{$t('NoMatchingOptionsMessage')}}
                          </template>
                        </v-select>
                        <div class="invalid-feedback">
                          <span>{{ validationVSelectErrors.CountryID }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group class="col-md-4"
                                  label-for="city"
                                  :label="$t('City')">
                      <ValidationProvider name="City" :rules="cityRules" v-slot="{ errors }">
                        <v-select transition="" v-model="model.cityID"
                                  :reduce="s => s.id" label="name"
                                  :searchable="true"
                                  :options="cityList"
                                  name="City"
                                  :class="(errors.length > 0 && setRequired('cityRequired') ? ' is-invalid' : '')">
                          <template v-slot:no-options>
                            {{$t('NoMatchingOptionsMessage')}}
                          </template>
                        </v-select>
                        <div class="invalid-feedback">
                          <span>{{ validationVSelectErrors.CityID }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group class="col-md-4"
                                  label-for="town"
                                  :label="$t('Town')">
                      <v-select transition="" v-model="model.townID"
                                :reduce="s => s.id" label="name"
                                :searchable="true"
                                :options="townList"
                                name="Town">
                        <template v-slot:no-options>
                          {{$t('NoMatchingOptionsMessage')}}
                        </template>
                      </v-select>
                    </b-form-group>
                    <b-form-group class="col-md-4"
                                  label-for="timezone"
                                  :label="$t('Timezone')">
                      <v-select transition="" v-model="model.timezone"
                                :reduce="s => s.Id" label="DisplayName"
                                :clearable="false"
                                :options="timeZoneList">
                        <template v-slot:no-options>
                          {{$t('NoMatchingOptionsMessage')}}
                        </template>
                      </v-select>
                    </b-form-group>
                    <b-form-group class="col-md-8"
                                  :label="$t('Address')"
                                  label-for="exampleFormControlTextarea1">
                      <b-form-textarea id="exampleFormControlTextarea1"
                                       v-model="model.customer_OtherInformation.address"
                                       rows="2"
                                       maxlength="500"></b-form-textarea>
                    </b-form-group>
                  </b-row>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
        <!--Other Information -->
        <b-row>
          <b-col lg="12">
            <iq-card>
              <template v-slot:body>
                <div class="new-user-info" style="color:black">
                  <h5 class="mb-3">{{ $t('OtherInformations')}}</h5>
                  <b-row>
                    <b-form-group class="col-md-4"
                                  label-for="smsPermission"
                                  :label="$t('SmsPermisson')">
                      <v-select transition="" v-model="model.enableSms"
                                :reduce="s => Number(s.id)" label="name"
                                :options="yesNoList">
                        <template v-slot:no-options>
                          {{$t('NoMatchingOptionsMessage')}}
                        </template>
                      </v-select>
                    </b-form-group>
                    <b-form-group class="col-md-4"
                                  label-for="emailPermission"
                                  :label="$t('EmailPermisson')">
                      <v-select transition="" v-model="model.enableEmail"
                                :reduce="s => Number(s.id)" label="name"
                                :options="yesNoList">
                        <template v-slot:no-options>
                          {{$t('NoMatchingOptionsMessage')}}
                        </template>
                      </v-select>
                    </b-form-group>
                    <b-form-group v-if="isEdit"
                                  class="col-md-4"
                                  label-for="type"
                                  :label="$t('Type')">
                      <v-select transition=""
                                v-model="model.customerTypeID"
                                :reduce="s => s.id" label="name"
                                :options="customerTypeList"
                                name="CustomerTypeID">
                        <template v-slot:no-options>
                          {{$t('NoMatchingOptionsMessage')}}
                        </template>
                      </v-select>
                    </b-form-group>
                    <b-form-group v-if="isEdit"
                                  class="col-md-4"
                                  label-for="cardNo"
                                  :label="$t('CardNo')">
                      <b-form-input v-model="model.cardNo"
                                    type="text"
                                    disabled
                                    maxlength="50"></b-form-input>
                    </b-form-group>
                    <b-form-group class="col-md-4"
                                  label-for="language"
                                  :label="$t('Language')">
                      <v-select transition="" v-model="model.languageID"
                                :reduce="s => s.id" label="name"
                                :options="languageList"
                                name="Language">
                        <template v-slot:no-options>
                          {{$t('NoMatchingOptionsMessage')}}
                        </template>
                      </v-select>
                    </b-form-group>
                    <b-form-group class="col-md-12"
                                  label-for="relatedStaff"
                                  :label="$t('RelatedStaff')">
                      <related-staff :staff-list="staffList" :model="model"></related-staff>
                    </b-form-group>
                    <b-form-group class="col-md-5" :label="$t('Tags')">
                      <customer-tags-input :customer-tags="model.customer_Tags"></customer-tags-input>
                    </b-form-group>
                    <b-form-group class="col-md-7" :label="$t('RegisterNote')">
                      <b-form-textarea v-model="model.registerNote" rows="3"></b-form-textarea>
                    </b-form-group>
                  </b-row>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
        <!--Medical Information -->
        <b-row>
          <b-col v-if="customerSettings.showMedicalSection" lg="12" style="color:black;">
            <iq-card>
              <template v-slot:body>
                <div class="new-user-info">
                  <h5 class="mb-3">{{ $t('MedicalInformations') }}</h5>
                  <b-row>
                    <b-form-group class="col-md-4" :label="$t('Allergies')">
                      <b-form-textarea v-model="model.customer_OtherInformation.allergies" rows="2"></b-form-textarea>
                    </b-form-group>
                    <b-form-group class="col-md-4" :label="$t('Drugs')">
                      <b-form-textarea v-model="model.customer_OtherInformation.drugs" rows="2"></b-form-textarea>
                    </b-form-group>
                    <b-form-group class="col-md-4" :label="$t('HealthIssues')">
                      <b-form-textarea v-model="model.customer_OtherInformation.healthIssues" rows="2"></b-form-textarea>
                    </b-form-group>
                    <b-form-group class="col-md-4" :label="$t('Operations')">
                      <b-form-textarea v-model="model.customer_OtherInformation.operations" rows="2"></b-form-textarea>
                    </b-form-group>
                    <b-form-group class="col-md-4" :label="$t('AlcoholOrSmoke')">
                      <b-form-textarea v-model="model.customer_OtherInformation.alcoholOrSmoke" rows="2"></b-form-textarea>
                    </b-form-group>
                    <b-form-group class="col-md-4" :label="$t('ChronicIllness')">
                      <b-form-textarea v-model="model.customer_OtherInformation.chronicIllness" rows="2"></b-form-textarea>
                    </b-form-group>
                    <b-form-group class="col-md-4" :label="$t('PreviousApplications')">
                      <b-form-textarea v-model="model.customer_OtherInformation.previousApplications" rows="2"></b-form-textarea>
                    </b-form-group>
                    <b-form-group class="col-md-4" :label="$t('TreatmentsWithMedicalDevices')">
                      <b-form-textarea v-model="model.customer_OtherInformation.treatmentsWithMedicalDevices" rows="2"></b-form-textarea>
                    </b-form-group>
                  </b-row>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
        <!--Save or Cancel Buttons -->
        <b-row>
          <b-col lg="12">
            <modal-footer-button :set-is-submitting="isSubmitting"
                                 @submit="submitNewCustomer"></modal-footer-button>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </b-container>
</template>
<script>
  import DatePicker from '../../../components/shared/DatePicker';
  import PhoneNumber from '../../../components/shared/PhoneNumber';
  import JobTagsInput from '../../../components/customer/JobTagsInput';
  import RelatedStaff from '../../../components/customer/RelatedStaff';
  import ModalFooterButton from '../../../components/shared/ModalFooterButton';
  import CustomerTagsInput from '../../../components/customer/CustomerTagsInput';

  import locationService from '../../../services/location';
  import customerService from '../../../services/customer';
  import commonService from '../../../services/common';
  import staffService from '../../../services/staff';

  export default {
    name: 'NewCustomer',
    props: {
      customerId: String
    },
    components: {
      DatePicker,
      PhoneNumber,
      JobTagsInput,
      RelatedStaff,
      ModalFooterButton,
      CustomerTagsInput,
    },
    data() {
      return {
        model: {
          id: '',
          customerTypeID: '',
          name: '',
          nameNormalized: '',
          phoneObject: { phoneSalt: '', internationalFormat: '', phoneCountryCode: '', phoneRegionCode: '', phoneNumberIsExists: null },
          phoneHome: '',
          phoneWork: '',
          nationalNumber: '',
          nationalityCountryID: '',
          timezone: '',
          eMail: '',
          genderID: null,
          dateOfBirth: '',
          countryID: '',
          cityID: '',
          townID: '',
          representID: '',
          leadID: '',
          referenceSourceID: '',
          enableSms: 1,
          enableEmail: 1,
          languageID: '',
          registerNote: '',
          createdSource: '',
          mobilUsername: '',
          mobilPassword: '',
          foreignPatientTypeID: null,
          customer_OtherInformation: {},
          customer_RelatedStaffs: [],
          customer_Tags: [],
          blockAutoChangeTypeID: false
        },
        validationVSelectErrors: {
          ReferenceSourceID: '',
          CountryID: '',
          CityID: '',
          RepresentID: '',
          DateOfBirth: '',
          CustomerSegmentID: '',
        },
        genderList: [],
        countryList: [],
        cityList: [],
        townList: [],
        timeZoneList: [],
        languageList: [],
        referenceSourceList: [],
        marialStatusList: [],
        customerTypeList: [],
        staffList: [],
        yesNoList: [],
        customerSettings: [],
        isEdit: false,
        isValid: false,
        isSubmitting: false,
        oldNationalNumber: '',
        oldCountryID: '',
        oldCityID: '',
        oldCustomerTypeID: '',
        foreignPatientTypeList:[]
      }
    },
    methods: {
      getCountryList: function () {
        locationService.getCountryList(this.$store.getters['Auth/currentLanguage']).then(
          (result) => {
            this.countryList = result;
          }
        );
      },
      getForeignPatientTypeList: function () {
        locationService.getForeignPatientTypeList().then(
          (result) => {
            this.foreignPatientTypeList = result;
          }
        );
      },
      getCities: function (countryId) {
        if (countryId != null) {
          locationService.getCityList(countryId).then(
            (result) => (this.cityList = result)
          );
        }
      },
      getTowns: function (cityId) {
        if (cityId != null) {
          locationService.getTownList(cityId).then(
            (result) => (this.townList = result)
          );
        }
      },
      getTimeZoneList: function () {
        locationService.getTimeZone().then(
          (result) => (this.timeZoneList = result)
        );
      },
      getGenders: function () {
        customerService.getGenders().then((response) => {
          this.genderList = response;
        })
      },
      getMarialStatusList() {
        customerService.getMarialStatus().then((response) => {
          this.marialStatusList = response;
        });
      },
      getTrueFalseList() {
        commonService.getTrueFalseList().then((response) => {
          this.yesNoList = response.map(v => {
            if (v.id == "false")
              v.id = 2
            else
              v.id = 1
            return v;
          });
        });
      },
      getLanguagesList() {
        commonService.getLanguagesList().then((response) => {
          this.languageList = response;
        });
      },
      getReferenceSources() {
        commonService.getReferenceSourceList().then(response => {
          this.referenceSourceList = response;
        });
      },
      getCustomerTypeList() {
        customerService.getCustomerTypeList(this.model.customerTypeID).then(response => {
          this.customerTypeList = response;
        });
      },
      getStaffList() {
        staffService.getStaffList().then(response => {
          this.staffList = response;
        });
      },
      getCustomer(id) {
        customerService.getCustomer(id).then(response => {
          this.model = response;

          this.isEdit = true;
          this.oldNationalNumber = this.model.nationalNumber;
          this.oldCountryID = this.model.countryID;
          this.oldCityID = this.model.cityID;
          this.oldCustomerTypeID = this.model.customerTypeID;
        });
      },
      getCustomerSettings() {
        customerService.getCustomerSetting()
          .then(response => {
            this.customerSettings = response;

            if (this.customerSettings.genderSelectedValue && !this.isEdit) {
              this.model.genderID = this.customerSettings.genderSelectedValue;
            }
          }).finally(() => {
            this.validateForVSelect()
          });
      },
      setRequired(field) {
        if (this.customerSettings != null) {
          return this.customerSettings[field];
        }
      },
      validateForVSelect() {
        this.isValid = true;

        if (this.setRequired('countryRequired') && !this.model.countryID) {
          this.validationVSelectErrors.CountryID = this.$t('ValidationMessage.CannotBeEmpty');
          this.isValid = false;
        }
        else { this.validationVSelectErrors.CountryID = ''; }

        if (this.setRequired('cityRequired') && !this.model.cityID) {
          this.validationVSelectErrors.CityID = this.$t('ValidationMessage.CannotBeEmpty');
          this.isValid = false;
        }
        else { this.validationVSelectErrors.CityID = ''; }

        if (this.setRequired('referenceSourceRequired') && !this.model.referenceSourceID) {
          this.validationVSelectErrors.ReferenceSourceID = this.$t('ValidationMessage.CannotBeEmpty');
          this.isValid = false;
        }
        else { this.validationVSelectErrors.ReferenceSourceID = ''; }

        if (this.setRequired('dateOfBirthRequired') && !this.model.dateOfBirth) {
          this.validationVSelectErrors.DateOfBirth = this.$t('ValidationMessage.CannotBeEmpty');
          this.isValid = false;
        }
        else { this.validationVSelectErrors.DateOfBirth = ''; }

        return this.isValid;
      },
      customerTypeChangeWarning() {
        let $this = this;
        let _html = `<hr/><div class='col-md-12 row' style='margin-top: 2%;'><div class='col-md-12'>${$this.$t("CustomerTypeChangeGeneralDesc")}</div></div>`;
        _html += `<div class='col-md-12 row' style='margin-top: 2%;'><div class='col-md-12'>${$this.$t("CustomerTypeChangePermanentUpdateDesc")}</div></div>`;
        _html += `<div class='col-md-12 row' style='margin-top: 2%;'><div class='col-md-12'>${$this.$t("CustomerTypeChangeCancelDesc")}</div></div><hr/>`;
        $this.$swal.fire({
          icon: 'warning',
          html: _html,
          width: '500px',
          buttonsStyling: false,
          confirmButtonText: $this.$t("UpdatePermanently"),
          confirmButtonClass: "btn btn-warning btn-sm",
          showCancelButton: true,
          cancelButtonText: $this.$t("Cancel"),
          cancelButtonClass: "btn btn-secondary btn-sm",
          reverseButtons: true

        }).then(function (result) {
          if (result.value) {
            $this.model.blockAutoChangeTypeID = true;
            $this.oldCustomerTypeID = $this.model.customerTypeID;
            $this.submitNewCustomer();
            return;
          }
          else if (result.dismiss === 'cancel') {
            $this.model.customerTypeID = $this.oldCustomerTypeID;
            $this.model.blockAutoChangeTypeID = false;
            return;
          }
        });
      },
      async submitNewCustomer() {
        let isValid = await this.$refs.customerFormObserver.validate();

        if (this.customerSettings.blockOnDuplicatePhoneNumber && this.model.phoneObject.phoneNumberIsExists) {
          isValid = false;
          this.$toastr.warning(this.$t("DuplicateNumberMessage"));
        }

        if (isValid) {
          let url = "/customer/new";
          if (this.model.id && this.model.id.length > 0) {
            url = "/customer/edit";
            if (this.oldCustomerTypeID != this.model.customerTypeID && this.model.blockAutoChangeTypeID != true) {
              this.customerTypeChangeWarning();
            }
          }

          this.model.enableSms = this.model.enableSms == 2 ? 0 : 1;
          this.model.enableEmail = this.model.enableEmail == 2 ? 0 : 1;

          if (this.oldCustomerTypeID == this.model.customerTypeID) {
            this.isSubmitting = true;

            customerService.postCustomerForm(url, this.model).then(response => {
              this.isSubmitting = false;
              if (response.success) {
                this.$toastr.success(this.$t("Success"));
                if (!this.isEdit && response.id != null) {
                  this.$router.push({ name: 'customerSummary.details', params: { customerId: response.id } });
                }
                else {
                  this.$router.push({ name: 'customerSummary.details', params: { customerId: this.model.id } });
                  this.$parent.getCustomerInfo();
                }
              }
              else {
                if (response.message && response.message.length > 0) {
                  this.$toastr.error(this.$t(response.message));
                }
                else {
                  this.$toastr.error(this.$t("Error"));
                }
              }
            }).finally(() => { this.isSubmitting = false; });
          }
        } else {
          this.$toastr.error(this.$t("ValidationMessage.EmptyAndValidateError"));
        }
      }
    },
    watch: {
      'model.name': function () {
        this.validateForVSelect();
      },
      selectedCountry: function (val) {
        if (!this.isEdit) {
          this.model.cityID = null;
          this.model.townID = null;
        }
        else {
          if (this.model.countryID != this.oldCountryID) {
            this.model.cityID = null;
            this.model.townID = null;
          }
        }

        this.validateForVSelect();
        this.getCities(val);
      },
      selectedCity: function (val) {
        this.validateForVSelect();
        this.getTowns(val);
      },
      'model.referenceSourceID': function () {
        this.validateForVSelect();
      },
      'model.dateOfBirth': function () {
        this.validateForVSelect();
      },
      'model.customerTypeID': function (val) {
        if (this.model.blockAutoChangeTypeID) {
          this.oldCustomerTypeID = val;
        }
      },
      'model.phoneObject.phoneRegionCode': function (val) {
        if (val) {
          const country = this.countryList.find(z => z.region?.toLowerCase() == val?.toLowerCase());
          if (country && !this.isEdit) {
            this.model.countryID = country.id;
            this.model.nationalityCountryID = country.id;
          }

          if (!this.isEdit)
            if (val?.toLowerCase() != this.$store.getters['Auth/tenantInfo'].countryCode) {
              this.model.enableSms = 2;
            }
            else {
              this.model.enableSms = 1;
            }
        }
      },
      'model.nationalityCountryID': function (val) {
        this.nationalityCountryID = val;
      },
      immediate: true
    },
    computed: {
      selectedCountry: function () {
        return this.model.countryID;
      },
      selectedCity: function () {
        return this.model.cityID;
      },
      identityNumberRule() {
        if (this.setRequired('citizenshipID_Required')) {
          return 'required';
        }
        return '';
      },
      countryRules() {
        if (this.setRequired('countryRequired')) {
          return 'required';
        }
        return '';
      },
      cityRules() {
        if (this.setRequired('cityRequired')) {
          return 'required';
        }
        return '';
      },
      referenceSourceRules() {
        if (this.setRequired('referenceSourceRequired')) {
          return 'required';
        }
        return '';
      },
    },
    mounted() {
      this.getCustomerSettings();

      if (this.customerId && this.customerId.length > 0) {
        this.model.id = this.customerId;
        this.isEdit = true;
        this.getCustomer(this.customerId);
      }
      else {
        this.model.countryID = this.$store.getters['Auth/tenantInfo'].mainCountryId;
        this.model.nationalityCountryID = this.$store.getters['Auth/tenantInfo'].mainCountryId;
        this.model.timezone = this.$store.getters['Auth/currentBranchTimezone'];
      }

      this.getCountryList();
      this.getTimeZoneList();
      this.getGenders();
      this.getMarialStatusList();
      this.getTrueFalseList();
      this.getLanguagesList();
      this.getReferenceSources();
      this.getCustomerTypeList();
      this.getStaffList();
      this.getForeignPatientTypeList();
    }
  }
</script>
